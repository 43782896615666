

























// @ts-nocheck
import { ForceDirectedChartCard } from '@/assets/bpchart/main.js'
// import * as ChartCardTypes from '@/assets/bpchart/types/charts/forceDirectedChartCard.ts'
// import * as ChartTypes from '@/assets/bpchart/types/components/forceDirectedChart.ts'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'

export default {
  props: {
    'chartOptions': {
      default () {
        return {
          name: 'force-directed-chart',
          title: '',
          subtitle: '',
          watermarkBase64: '',
          // logoBase64: '',
          footerHtml: '',
          tooltip: '',
          size: 'auto',
          style: {
            width: 'auto',
            height: 'auto',
            'padding-top': 16,
            'padding-right': 20,
            'padding-bottom': 5,
            'padding-left': 20,
            header: {
              height: 85
            },
            footer: {
              height: 25
            }
          }
        }
      }
    },
    'data': {
      default () {
        return {
          nodes: [],
          links: []
        }
      }
    },
    'screenShotMode': {
      default () {
        return false
      }
    },
    'styleConfig': {
      required: false
    },
  },
  data () {
    return {
      // dataSet: {
      //   nodes: [],
      //   links: []
      // },
      allNodes: [],
      allLinks: [],
      chart: null,
      isRendered: false, // 是否已把圖表放上
    }
  },
  computed: {
    syncScreenShotMode: {
      get () {
        return this.screenShotMode
      },
      set (v) {
        this.$emit('update:screenShotMode', v)
      }
    },
    arrows () {
      if (!this.styleConfig || !this.styleConfig.arrows || !this.styleConfig.styles) {
        return []
      }
      return this.styleConfig.arrows.map(arrow => {
        return {
          id: arrow,
          style: this.styleConfig.styles[arrow]
        }
      })
    }
  },
  async mounted () {
    // -- init widget --
    this.chart = new ForceDirectedChartCard(
      window.document.getElementById(this.chartOptions.name),
      this.chartOptions  
    )
    // 點擊node事件監聽
    this.chart.onNodeClick((d) => {
      const callback = (id) => {
        this.chart.toggleTopNodes(id)
      }
      this.$emit('handleNodeClick', d, callback)
    })
    this.chart.onExtandBtnClick((d) => {
      const callback = (id) => {
        this.chart.toggleTopNodes(id)
      }
      this.$emit('handleExtandBtnClick', d, callback)
    })
    // 預設參數
    this.chart.screenShotMode(this.syncScreenShotMode)
    if (this.styleConfig) {
      this.setStyle(this.styleConfig)
    }
    // 初始化
    // console.log('mounted')
    if (this.data.nodes.length) {
      // console.log('mounted init')
      this.chart.init(this.data)
      this.isRendered = true
    }
  },
  watch: {
    data: {
      handler (d) {
        // console.log('watch')
        if (this.isRendered) {
          // console.log('watch resetNodes')
          this.chart.resetNodes(d)
        } else {
          // console.log('watch init')
          this.chart.init(d)
          this.isRendered = true
        }
      }
    },
    syncScreenShotMode: {
      handler (d) {
        this.chart.screenShotMode(d)
      },
    },
    styleConfig: {
      handler (d) {
        if (!d) {
          return
        }
        this.setStyle(d)
      },
    }
  },
  methods: {
    setStyle (styleConfig) {
      this.chart.setStyle(originStyleConfig => {
        let newStyleConfig = originStyleConfig
        // styles
        if (styleConfig.styles) {
          Object.keys(styleConfig.styles).forEach(key => {
            newStyleConfig.styles[key] = styleConfig.styles[key]
          })
        }
        // arrows
        newStyleConfig.arrows = styleConfig.arrows
        // circlesR
        if (styleConfig.circlesR) {
          Object.keys(styleConfig.circlesR).forEach(key => {
            newStyleConfig.circlesR[key] = styleConfig.circlesR[key]
          })
        }
        return newStyleConfig
      })
    }
  }
}
